import React, { Dispatch, ReactElement, SetStateAction, useState } from 'react';
import './Actions.scss';

const Actions = (props: {
    addTree: Dispatch<SetStateAction<void>>;
    startSnow: Dispatch<boolean>;
    canAddTree: boolean;
    canLetItSnow: boolean;
}): ReactElement => {
    const [snow, setSnow] = useState(false as boolean);

    const onAddTree = () => {
        if (props.canAddTree) {
            props.addTree();
        }
    };

    const onSnow = () => {
        if (props.canLetItSnow && !snow) {
            setSnow(true);
            props.startSnow(true);
        }
    };

    return (
        <div className="Actions">
            <div className={`button noselect ${!props.canAddTree && 'disabled'}`} onClick={onAddTree}>
                <img src="https://img.icons8.com/external-kiranshastry-solid-kiranshastry/100/000000/external-tree-camping-kiranshastry-solid-kiranshastry-1.png" />
            </div>

            <div className={`button noselect ${(!props.canLetItSnow || snow) && 'disabled'}`} onClick={onSnow}>
                <img src="https://img.icons8.com/windows/96/000000/snowflake.png" />
            </div>
        </div>
    );
};

export default Actions;
