/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement, useEffect, useState } from 'react';
import './Field.scss';
import Actions from '../actions/Actions';
import { gsap } from 'gsap';
import { CSSPlugin } from 'gsap/CSSPlugin';
import Snow from '../snow/snow';
import Forest from '../forest/Forest';

const Field = (): ReactElement => {
    const [trees, setTrees] = useState([] as any[]);
    const [availableTrees, setAvailableTrees] = useState([] as any[]);
    const [snow, setSnow] = useState(false);
    const [snowLayers, setSnowLayers] = useState([] as any[]);

    useEffect(() => {
        gsap.registerPlugin(CSSPlugin);
        const treeElements = Array.from(document.getElementsByClassName('tree'));
        const cloud = document.getElementById('cloud2');
        const trees: any[] = [];
        treeElements.forEach((tree) => {
            gsap.to(tree, { scale: 0, transformOrigin: 'center bottom', duration: 0 });
            trees.push({ htmlElement: tree, isVisible: false });
        });
        gsap.to(cloud, { x: 700, ease: 'sine.out', repeat: -1, yoyo: true, duration: 20 });
        setTrees(trees);
        setAvailableTrees(trees);
        setTrees(trees);

        const snow1Elements = Array.from(document.getElementsByClassName('snow1'));
        const snow1Array: any[] = [];
        snow1Elements.forEach((snow1) => {
            gsap.to(snow1, { opacity: 0, duration: 0 });
            snow1Array.push({ htmlElement: snow1, isVisible: false });
        });

        const snow2Elements = Array.from(document.getElementsByClassName('snow2'));
        const snow2Array: any[] = [];
        snow2Elements.forEach((snow2) => {
            gsap.to(snow2, { opacity: 0, duration: 0 });
            snow2Array.push({ htmlElement: snow2, isVisible: false });
        });

        const snow3Elements = Array.from(document.getElementsByClassName('snow3'));
        const snow3Array: any[] = [];
        snow3Elements.forEach((snow3) => {
            gsap.to(snow3, { opacity: 0, duration: 0 });
            snow3Array.push({ htmlElement: snow3, isVisible: false });
        });

        setSnowLayers([snow1Array, snow2Array, snow3Array]);
    }, []);

    const onAddTree = () => {
        if (!availableTrees.length) {
            return;
        }
        const tree = getRandomTree();
        animateTree(tree);
    };

    const startSnow = (active: boolean) => {
        showSnow();
        setSnow(active);
    };

    const animateTree = (tree: any) => {
        const timeline = gsap.timeline();
        timeline.fromTo(tree.htmlElement, 1, { scale: 0 }, { scale: 1, transformOrigin: 'center bottom' });
    };

    const getRandomTree = () => {
        const randomNumber = Math.floor(Math.random() * availableTrees.length);
        const randomTree = availableTrees[randomNumber];

        setAvailableTrees(availableTrees.filter((el, index) => index !== randomNumber));

        return randomTree;
    };

    const addSnowOnTree = (tree: any, timeline: any) => {
        // this could come as undefined
        if (!tree) {
            return;
        }

        animateSnow(tree, timeline);
    };

    const animateSnow = async (snow: any, timeline: any) => {
        timeline.fromTo(snow.htmlElement, 1, { opacity: 0 }, { opacity: 1 });
    };

    const getRandomSnowElement = () => {
        // this will get a random tree
        const randomY = Math.floor(Math.random() * snowLayers[0].length);
        // selected tree to be selected
        // could remain undefined
        let selectedTree;
        // generate copy of state
        const snowLayersCopy = [...snowLayers];

        // check the from first to last level of snow and them in order
        // add snow to the lowest level possible
        if (!snowLayers[0][randomY].isVisible) {
            snowLayersCopy[0][randomY].isVisible = true;
            selectedTree = snowLayersCopy[0][randomY];
        } else if (!snowLayers[1][randomY].isVisible) {
            snowLayersCopy[1][randomY].isVisible = true;
            selectedTree = snowLayersCopy[1][randomY];
        } else if (!snowLayers[2][randomY].isVisible) {
            snowLayersCopy[2][randomY].isVisible = true;
            selectedTree = snowLayersCopy[2][randomY];
        }

        // setSnowLayers([...snowLayersCopy]);
        return selectedTree;
    };

    const showSnow = async () => {
        const timeline = gsap.timeline();

        setInterval(() => {
            const snowElement = getRandomSnowElement();
            addSnowOnTree(snowElement, timeline);
        }, 1500);
    };

    return (
        <div className="Field">
            <div className="snow-container">
                <Snow active={snow} />
            </div>
            <div id="actions-container">
                <Actions
                    addTree={onAddTree}
                    startSnow={startSnow}
                    canAddTree={availableTrees.length > 0}
                    canLetItSnow={availableTrees.length === 0}
                />
                <div className="stats">
                    {trees.length - availableTrees.length}
                    <hr></hr>
                    {trees.length}
                </div>
            </div>
            <div className="svg-container">
                <Forest />
            </div>
        </div>
    );
};

export default Field;
