import React, { ReactElement } from 'react';
import './App.scss';
import Field from './components/field/Field';

const App = (): ReactElement => {
    return (
        <div className="App">
            <div className="Field-container">
                <Field />
            </div>
        </div>
    );
};

export default App;
